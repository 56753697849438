import React from 'react';
import imagenElectricista3 from './images/Prueba.png';
import './styles/Inicio.css';

const Inicio = () => {
  return (
    <div className="inicio-container">
      <div id="info">
      <h2>Servicios Profesionales de Electricistas Expertos en Pereira</h2>
        <p>
        En nuestra empresa ofrecemos una amplia gama de servicios eléctricos generales para satisfacer todas tus necesidades. Ya sea que necesites reparaciones, instalaciones, mantenimiento o asesoramiento, nuestro equipo de expertos electricistas está listo para ayudarte. Nos especializamos en atender tanto a hogares como a empresas y negocios, proporcionando soluciones profesionales confiables y eficientes.
        </p>
          <img id='logo' src={imagenElectricista3} alt="Electricistas Pereira 3165002396" />  {/*Utiliza la imagen importada */}
        <p/>
      </div>
      <div id="imageini">
      </div>
    </div>
  )
};

export default Inicio;
