import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp , faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import './styles/estilos.css';
import AppRouter from './AppRouter';
import { isMobile } from 'react-device-detect';

function Footer() {
  const handleChatClick = () => {
    redirectToWhatsApp();
  };

  const redirectToWhatsApp = () => {
    console.log(String(isMobile));
    const phoneNumber = '+573165002396'; // Reemplaza esto con el número de teléfono deseado
    const message = encodeURIComponent('Hola, quiero obtener mas información sobre sus servicios de electricistas. \n'); // Reemplaza con tu mensaje predeterminado
  
    if (isMobile) {
      window.location.href = `whatsapp://send?phone=${phoneNumber}&text=${message}`;
    } else {
      const chatConfirmed = window.confirm('¿Quieres chatear con nosotros por WhatsApp?');
      if (chatConfirmed) {
        window.open(`https://web.whatsapp.com/send?phone=${phoneNumber}&text=${message}`, '_blank');
      }
    }
  };
  

  return (
    <footer>
      <p>
      <a href="https://www.instagram.com/electricistaspereira?igsh=ajI2Y3lwbGU0aTZk" target="_blank" rel="noopener noreferrer nofollow"className="instagram social">
        <FontAwesomeIcon icon={faInstagram} size="2x" /> @electricistaspereira </a>
        <a href="https://web.facebook.com/ElectricistasEnPereira" target="_blank"rel="noopener noreferrer nofollow" className="facebook social">
        <FontAwesomeIcon icon={faFacebook} size="2x" /> ElectricistasEnPereira </a>
        <FontAwesomeIcon icon={faWhatsapp} size="2x"/>+573165002396 © Electricistas Pereira
      </p>
      <button id='whatsapp' onClick={handleChatClick}>Chatear por WhatsApp</button>
    </footer>

  );
}


function App() {
  return (
    <div>
      <header/>
      <AppRouter />
      <Footer />
    </div>
  );
}

export default App;
