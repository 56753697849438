import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp , faFacebook, faInstagram, faGoogle } from "@fortawesome/free-brands-svg-icons";
import imagenElectricista2 from './images/Isotipo_Industrial_Facebook.png';
import './styles/Inicio.css';

const Contacto = () => {
  return (
    <div className="contacto-container">
      <p><a href="https://www.instagram.com/electricistaspereira?igsh=ajI2Y3lwbGU0aTZk" target="_blank" rel="noopener noreferrer nofollow"className="instagram social">
      <FontAwesomeIcon icon={faInstagram} size="2x" /> @electricistaspereira </a></p>
      <p><a href="https://web.facebook.com/ElectricistasEnPereira" target="_blank"rel="noopener noreferrer nofollow" className="facebook social">
      <FontAwesomeIcon icon={faFacebook} size="2x" /> ElectricistasEnPereira </a></p>
      <p><FontAwesomeIcon icon={faWhatsapp} size="2x"/>+573165002396 © Electricistas Pereira</p>
      <p><FontAwesomeIcon icon={faGoogle} size="2x"/> isomec.colombia@gmail.com - luis.holguin40141@ucaldas.edu.co</p>
      <img src={imagenElectricista2} alt="Contacto" /> {/* Utiliza la imagen importada */}
      <p/>
    </div>
  );
};

export default Contacto;