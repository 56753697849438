import React from 'react';
import imagenElectricista from './images/Prueba.png';
import './styles/Inicio.css';

const Servicios = () => {
  const serviciosResidencial = [
    'Instalación de cableado eléctrico en viviendas.',
    'Mantenimiento y reparación de sistemas eléctricos en hogares.',
    'Instalación de iluminación interior y exterior.',
    'Colocación de enchufes y tomas de corriente adicionales.',
    'Solución de cortocircuitos y apagones.',
  ];

  const serviciosComercial = [
    'Diseño e implementación de sistemas eléctricos para locales comerciales.',
    'Mantenimiento preventivo y correctivo en negocios y oficinas.',
    'Instalación de sistemas de seguridad y cámaras.',
    'Optimización del consumo eléctrico para reducir costos.',
    'Instalación de sistemas de energía de respaldo (generadores).',
  ];

  const serviciosIndustrial = [
    'Montaje y mantenimiento de sistemas eléctricos industriales.',
    'Instalación de tableros de control y distribución.',
    'Solución de problemas eléctricos en entornos industriales.',
    'Mantenimiento de maquinaria y equipos eléctricos.',
    'Instalación de sistemas de energía trifásica.',
  ];

  return (
    <div className="servicios-container">
      <h4>Servicios Residencial</h4>
      <ul>
        {serviciosResidencial.map((servicio, index) => (
          <li key={index}>{servicio}</li>
        ))}
      </ul>

      <h4>Servicios Comercial</h4>
      <ul>
        {serviciosComercial.map((servicio, index) => (
          <li key={index}>{servicio}</li>
        ))}
      </ul>

      <h4>Servicios Industrial</h4>
      <ul>
        {serviciosIndustrial.map((servicio, index) => (
          <li key={index}>{servicio}</li>
        ))}
      </ul>
      <div>
        <img id='logo' src={imagenElectricista} alt="Electricistas Pereira 3165002396" />  {/*Utiliza la imagen importada */}
      </div>
    </div>
  );
};

export default Servicios;
