import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp , faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import bannerImage from './images/Logo-con-borde-blanco_Sin-fondo-.png'; // Importa la imagen
import Inicio from './Inicio';
import AcercaDe from './AcercaDe';
import Servicios from './Servicios';
import Contacto from './Contacto';
import InstagramPhotos from './InstagramPhotos';


function AppRouter() {
  return (
    <Router>
      <nav>
        <ul>
          <button><li><Link to="/">Inicio</Link></li></button>
          <button><li><Link to="/acerca">Acerca de</Link></li></button>
          <button><li><Link to="/servicios">Servicios</Link></li></button>
          <button><li><Link to="/contacto">Contacto</Link></li></button>
        </ul>
      </nav>
      <div id="banner">
        <div id="texto">
        <h2>RESPONSABILIDAD Y CALIDAD EN CADA CONEXIÓN</h2>
        <p/>
        <p><a href="https://www.instagram.com/electricistaspereira?igsh=ajI2Y3lwbGU0aTZk" target="_blank" rel="noopener noreferrer nofollow"className="instagram social">
        <FontAwesomeIcon icon={faInstagram} size="2x" /> @electricistaspereira </a><a href="https://web.facebook.com/ElectricistasEnPereira" target="_blank"rel="noopener noreferrer nofollow" className="facebook social">
        <FontAwesomeIcon icon={faFacebook} size="2x" /> ElectricistasEnPereira </a> <FontAwesomeIcon icon={faWhatsapp} size="2x"/>+573165002396 © Electricistas Pereira </p>
        </div>
        <img src={bannerImage} alt="Banner" />  {/*Utiliza la imagen importada */}
      </div>
      <div id='contenido'>
      <Routes>
        <Route path="/" element={<Inicio />} />
        <Route path="/acerca" element={<AcercaDe />} />
        <Route path="/servicios" element={<Servicios />} />
        <Route path="/contacto" element={<Contacto />} />
      </Routes>
      <InstagramPhotos/>
      </div>
    </Router>
  );
}

export default AppRouter;
