import React from 'react';
import imagenElectricista3 from './images/Prueba.png';
import './styles/Inicio.css';

const AcercaDe = () => {
  return (
    <div className="inicio-container">
      <div id="info">
        <h3>Acerca de nosotros</h3>
        <p>
          Somos una empresa de electricistas con años de experiencia en el campo.
          Nuestro objetivo es brindar servicios eléctricos confiables y de alta
          calidad para satisfacer las necesidades de nuestros clientes.
        </p>
        <p>
            Contamos con un equipo de electricistas capacitados y certificados que
            están listos para abordar cualquier tipo de trabajo eléctrico, ya sea
            instalaciones, reparaciones, mantenimiento o asesoramiento técnico.
        </p>
          <img id='logo' src={imagenElectricista3} alt="Electricistas Pereira 3165002396" />  {/*Utiliza la imagen importada */}
        <p/>
      </div>
      <div id='imageAcerca'> 
      </div>
    </div>
  )
};

export default AcercaDe;
