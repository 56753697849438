// InstagramPhotos.js
import React from 'react';
import './styles/estilos.css';

const InstagramPhotos = () => {

  return (
      <div id='instagram'>
        <iframe src="https://snapwidget.com/embed/1059890" class="snapwidget-widget" allowtransparency="true" frameborder="0" scrolling="no"></iframe>
      </div>
  );
};

export default InstagramPhotos;
